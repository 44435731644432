@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a, p, h1, h2, h3, h4 {
    font-family: "Overpass Mono", monospace;
    font-optical-sizing: auto;
    cursor: default;
  }
}